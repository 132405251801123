import React, { useContext, useEffect, useRef, useState } from "react";
import DoctorSidebar from "../../Component/Doctor/Layout/DoctorSidebar";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import { IoIosArrowDown } from "react-icons/io";
import { isAuth } from "../../auth/helper";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import GlobalStorage from "../../Storage/ContextProvider";
import LightLogo from "../../images/newLogo.png";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Margin, usePDF } from "react-to-pdf";
import DoctorImage from "../../images/patientImage.png";
import { FaRegUserCircle } from "react-icons/fa";

const Transcribe = () => {
  const [translatedData, setTranslatedData] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [title, setTitle] = useState("");

  const [reportId, setReportId] = useState("");
  const [disableSelect, setDisableSelect] = useState(true);
  const [savePopup, setSavePopup] = useState(false);
  const params = useParams();
  const [rawData, setRawData] = useState("");
  const [processedData, setProcessedData] = useState("");
  const { patientDetail, setPatientDetail, doctorProfile } =
    useContext(GlobalStorage);
  const [patientDetail2, setPatientDetail2] = useState();
  const [height, setHeight] = useState("auto");
  const [cleanProcessedData, setCleanProcessedData] = useState("");
  const [transcribingBtn, setTranscribingBtn] = useState("Transcribe");
  const [transCribeClick, setTranscribeClick] = useState(false);
  const [hName, setHname] = useState("");

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const location = useLocation();
  const simplifiedtextareaRef = useRef(null);

  const medicalData = location?.state?.rawData;
  const simplifiedData = location?.state?.processedData;

  useEffect(() => {
    setRawData(medicalData);
    setProcessedData(simplifiedData?.replace(/\\n/g, '\n'));
  }, []);

  const removeIrrelevant = (report) => {
    try {
      const patt = /\w+:\n\n+/;
      const splitOutput = report.split(patt);
      return splitOutput[splitOutput.length - 1].trim();
    } catch (error) {
      return report;
    }
  };

  const renderTextWithLineBreaks = (text) => {
    return text.split("\n").map((line, index) => <p key={index}>{line}</p>);
  };
  const fData = (data) => {
    return data?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  // useEffect(() => {
  //   const res_clean = removeIrrelevant(processedData);
  //   // setProcessedData(res_clean);
  //   setProcessedData(res_clean);
  // }, [])

  // const { rawData, setRawData, processedData, setProcessedData } =
  //   useContext(GlobalStorage);
  const ref = useRef();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSavePopup(false);
          // alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // const wrapperRef = useRef(null);
  useOutsideAlerter(ref);
  const language = [
    {
      value: "",
      name: "Select Language",
    },
    {
      value: "pa",
      name: "Punjabi ",
    },
    {
      value: "es",
      name: "Spanish",
    },
    {
      value: "pt",
      name: "Portuguese (Brazil)",
    },

    {
      value: "ar",
      name: "Arabic",
    },
    {
      value: "de",
      name: "German",
    },
    {
      value: "hi",
      name: "Hindi",
    },
    {
      value: "sr",
      name: "Serbian",
    },
    {
      value: "ur",
      name: "Urdu",
    },
    {
      value: "fr",
      name: "French",
    },
    {
      value: "so",
      name: "Somali",
    },
    {
      value: "uk",
      name: "Ukrainian",
    },
    {
      value: "zh-TW",
      name: "Chinese (Traditional)",
    },

    {
      value: "am",
      name: "Amharic",
    },
  ];

  useEffect(() => {
    loadDetailPatient();
  }, []);

  const loadDetailPatient = () => {
    axios({
      method: "POST",
      url: `${BASE_URL}/patient/detail`,
      data: { patientId: params?.patientId },
    })
      .then((response) => {
        console.log("Get Patient wwwwwwwwwwwDetail", response?.data?.name);
        setPatientDetail2(response?.data);
      })
      .catch((error) => {
        console.log("Get Patient Detail ERROR", error.response);
      });
  };

  useEffect(() => {
    hospitalDetail();
  }, [savePopup]);

  const hospitalDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/${doctorProfile?.hospitalId}`,
    })
      .then((response) => {
        console.log("Get HospitalProfile", response?.data);
        setHname(response?.data?.hospitalName);
      })
      .catch((error) => {
        console.log("Get Hospital  Detail ERROR", error.response);
      });
  };
  const handlePublish = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const data = {
      originalReport: rawData,
      transcribedText: processedData,
      nativeLanguageTranscribedText: translatedData,
      isPublished: true,
      title,
    };
    const publishReport = await axios({
      method: "PUT",
      url: `${BASE_URL}/reports/${reportId}`,
      data,
    })
      .then((response) => {
        console.log("Report Update Success", response);
        // setReportId(response?.data?.reportId);
        // setDisableSelect(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(" Report Update  Error", error.response.data);
      });
  };
  const handleSave = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const data = {
      doctorId: isAuth()?._id,
      patientId: params?.patientId,
      originalReport: rawData,
      transcribedText: processedData,
      nativeLanguageTranscribedText: translatedData,
      isPublished: true,
      title,
    };
    const saveReport = await axios({
      method: "POST",
      url: `${BASE_URL}/reports`,
      data,
    })
      .then((response) => {
        console.log("Save Report Success", response);
        // setReportId(response?.data?.reportId);
        // setDisableSelect(false);
        toast.success("Medical Report Saved Successfully");
      })
      .catch((error) => {
        console.log("Save Report  Error", error.response.data);
      });
  };

  const handleSave2 = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const data = {
      doctorId: isAuth()?._id,
      patientId: params?.patientId,
      originalReport: rawData,
      transcribedText: processedData,
      nativeLanguageTranscribedText: translatedData,
      isPublished: false,
      title,
    };
    const saveReport = await axios({
      method: "POST",
      url: `${BASE_URL}/reports`,
      data,
    })
      .then((response) => {
        // console.log("Save Report Success", response);
        // setReportId(response?.data?.reportId);
        // setDisableSelect(false);
        toast.success("Medical Report Saved Successfully");
      })
      .catch((error) => {
        console.log("Save Report  Error", error.response.data);
      });
  };

  const handleTranscribeData = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    setTranscribingBtn("Transcribing...");
    setTranscribeClick(true);
    const formData = new FormData();
    formData.append("doctorId", isAuth()?._id);
    formData.append("patientId", params?.patientId);
    formData.append("medTextReport", rawData);

    const transcribeData = await axios
      .post(`${BASE_URL}/transcribe`, formData)
      .then((response) => {
        console.log("Transcribe Success", response);
        setProcessedData(response?.data?.report?.transcribe?.replace(/\\n/g, '\n'));
        setTranscribingBtn("Transcribe");
        setTranscribeClick(false);
      })
      .catch((error) => {
        console.log("Transcribe  Error", error.response.data);
        setTranscribingBtn("Transcribe");
        setTranscribeClick(false);
      });
  };

  const handleTranslatedData = async () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const transcribeData = await axios({
      method: "POST",
      url: `${BASE_URL}/translate`,
      data: {
        text: processedData ? processedData : rawData,
        sourceLanguage: "en",
        targetLanguage: selectedLanguage,
        doctorId: isAuth()?._id,
      },
    })
      .then((response) => {
        console.log("Translate Success", response);
        setTranslatedData(response.data.translatedText);
      })
      .catch((error) => {
        console.log("Transcribe  Error", error.response.data);
      });
  };
  // const handleDownload = () => {
  const content = ref.current;
  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: "multipage-example.pdf",
    page: { margin: Margin.MEDIUM },
  });



  return (
    <DoctorSidebar>
      <div
        // className="p-[20px]  text-[#052721] dark:text-[#fff]"
        className={`${
          savePopup
            ? "bg-[rgba(0,0,0,0.9)] opacity-[.04] dark:opacity-[.3] overflow-hidden h-[90vh]"
            : " text-[#052721] dark:text-[#fff]"
        } p-[20px]  `}
      >
        <div className="dark:bg-[var(--dark-primary)] bg-[#fff] w-[100%] h-[160px] flex justify-between items-center p-[20px]">
          <div className="flex items-center gap-[20px] ">
            {/* <img
              src={DoctorImage}
              className="w-[97px] h-[97px] rounded-full"
              alt=""
            /> */}
            <FaRegUserCircle className="text-[65px]" />
            <div>
              <p className="text-[25px] font-[500]">
                Patient:{patientDetail2?.name}
              </p>
              <p className="text-[15px] font-[400]">Record ID: #11323094</p>
              {/* <p className="text-[15px] font-[400] dark:text-[#FFCFAA]">
                Date Transcribed: 12th January, 2024
              </p> */}
            </div>
          </div>
          <div className="flex items-center gap-[25px] text-[#fff]">
            {/* <button className='w-[190px] h-[45px] bg-[#67A599] rounded-full font-inter font-[600] text-[14px] '>Export Transcript</button> */}
            {/* <button className="w-[190px] h-[45px] bg-[#FF0000] rounded-full font-inter font-[600] text-[14px]">
              Delete Transcript
            </button> */}
          </div>
        </div>
        {/* <h2 className="font-[500] text-[25px]">New Record</h2> */}
        <div className="w-[100%] flex items-center gap-[25px] my-[30px]">
          <div className="w-[50%]">
            <button className="w-full h-[53px] bg-[var(--doctor-color)] text-[#fff] rounded-[20px] font-inter font-[700] text-[21px]">
              Medical Note
            </button>
          </div>
          <div className="w-[50%]">
            <button className="w-full h-[53px] bg-[var(--doctor-color)] text-[#fff] rounded-[20px] font-inter font-[700] text-[21px]">
              Simplified Note
            </button>
          </div>
        </div>

        <div className="mb-5">
          <input
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            // value={removeIrrelevant(processedData)}
            value={title}
            className="  w-full  p-[10px] outline-none bg-[#fff]"
            placeholder="Please Enter the title..."
          />
        </div>
        <div className="w-[100%] flex item-center gap-[25px]">
          <div className="h-[300px] w-[50%] bg-[#fff] dark:bg-[var(--dark-primary)] p-[25px]  rounded-[20px]">
            <textarea
              value={rawData}
              onChange={(e) => setRawData(e.target.value)}
              className="  w-full max-h-[170px] min-h-[170px] p-[10px] bg-transparent  outline-none"
              placeholder="input raw data here...."
            ></textarea>

            <div className="flex justify-end mt-[22px]">
              <button
                disabled={transCribeClick}
                className={` ${
                  transCribeClick ? "opacity-40" : "opacity-100"
                } w-[165px] h-[50px] bg-[var(--doctor-color)] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] text-[#fff] text-[20px] font-inter font-[600] rounded-full`}
                style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
                onClick={handleTranscribeData}
              >
                {transcribingBtn}
              </button>
            </div>
          </div>
          <div className="h-[300px] w-[50%] bg-[#fff] dark:bg-[var(--dark-primary)] p-[25px]  rounded-[20px]">
            <textarea
              onChange={(e) => setProcessedData(e.target.value)}
              // value={removeIrrelevant(processedData)}
              value={processedData}
              className="  w-full max-h-[170px] min-h-[170px] p-[10px] bg-transparent outline-none"
              placeholder="Transcribe data shows here..."
            ></textarea>

            <div className="flex justify-end mt-[22px]">
              <button
                className="w-[165px] h-[50px] bg-[#DDBEA6] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] text-white text-[20px] font-inter font-[600] rounded-full"
                style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
                onClick={handleSave2}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
        <div className="w-[100%] flex items-center gap-[20px] font-inter font-[600] text-[21px] text-[#fff] my-[20px]">
          <button className="w-[60%] h-[50px] bg-[var(--doctor-color)] rounded-[20px]">
            Translated Note
          </button>
          {/* <button className="w-[25%] h-[50px] bg-[#67A599] opacity-[.4] rounded-[20px] flex justify-between items-center px-6">
           <p> Select Language</p>
          <IoIosArrowDown size={24}  />
          </button> */}
          <select
            id="time"
            className={`${
              disableSelect ? "" : "opacity-100 cursor-pointer"
            } outline-none bg-[var(--doctor-color)]  rounded-full h-[50px]  px-6 dark:bg-[var(--dark-primary)] text-[16px] w-[25%] `}
            value={selectedLanguage}
            // disabled={disableSelect}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            {language?.map((data, index) => {
              return (
                <>
                  <option value={data?.value} className="py-3">
                    {data?.name}
                  </option>
                </>
              );
            })}
          </select>
          <button
            // disabled={disableSelect}
            className={` ${
              disableSelect ? "" : "opacity-100 cursor-pointer"
            } w-[15%] h-[50px] bg-[var(--doctor-color)]  rounded-[20px]`}
            onClick={handleTranslatedData}
          >
            Translate
          </button>
        </div>

        <div className="my-[10px]">
          <textarea
            className=" w-[100%] max-h-[250px] min-h-[250px] bg-[#fff] dark:bg-[var(--dark-primary)] outline-none p-[20px] rounded-[20px]"
            value={translatedData}
            placeholder="Translated  data shows here"
          ></textarea>
        </div>
        <div className="my-[20px] flex items-center gap-[20px] font-inter font-[600] text-[21px] text-[#fff]">
          <button
            className="w-[40%] h-[50px] bg-[#DDBEA6] dark:text-[var(--dark-primary)] rounded-full "
            style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
            onClick={() => setSavePopup(true)}
          >
            Save or Print
          </button>

          <button
            className={` ${
              disableSelect ? "" : "opacity-100 cursor-pointer"
            } w-[60%] h-[50px] bg-[var(--doctor-color)]  rounded-full`}
            // className="w-[60%] h-[50px] bg-[var(--doctor-color)]  opacity-[.25] rounded-full "
            style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
            // onClick={handlePublish}
            onClick={handleSave}
          >
            Save & Share
          </button>
        </div>
      </div>
      {savePopup && (
        <>
          <div className="absolute top-[150px] bg-white w-[950px] min-h-[200px] max-h-[500px] overflow-y-scroll  left-[28%] right-[20%] rounded-lg">
            <div ref={ref}>
              <div className="text-black p-[35px] relative  rounded-2xl">
                <div id="contentPad" className="p-12 text-lg">
                  <div className="flex items-center justify-between mb-7 ">
                    <div className="">
                      <img src={LightLogo} className="w-[120px] h-auto" alt="" />
                    </div>
                    <div className=" flex items-center gap-1">
                      <p className="font-[500]">Hospital Name - </p>
                      <p className="font-[500] text-[26px]">{hName}</p>
                    </div>
                  </div>

                  <p>
                    Date - {currDate} {currTime}
                  </p>

                  <h2 className="text-xl mt-6 font-[600]">Medical Report:</h2>
                  {/* <p>{rawData}</p> */}
                  <p>{fData(rawData)}</p>

                  <h2 className="text-xl mt-6 font-[600]">
                    Simplified Report:
                  </h2>
                  {/* <p>{processedData}</p> */}

                  <p>{fData(processedData)}</p>
                  <h2 className="text-xl mt-6 font-[600]">
                    Translated Report:
                  </h2>
                  <p>{fData(translatedData)}</p>

                  {/* <p>{translatedData}</p> */}
                </div>
                <div className="w-full flex items-center justify-center gap-6 mt-6 mb-6">
                  {/* <button
                    className="flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg"
                    onClick={() => toPDF()}
                  >
                    Save
                  </button> */}

                  <ReactToPrint
                    pageStyle={{ margin: "15px" }}
                    content={() => ref.current}
                    trigger={() => (
                      <button
                        className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                        // onClick={() => setSavePopup(false)}
                      >
                        Save or Print
                      </button>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DoctorSidebar>
  );
};

export default Transcribe;
